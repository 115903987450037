import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import MenuIcon from "@mui/icons-material/Menu";
import SyncIcon from "@mui/icons-material/Sync";
import DrawerAppBar from "./Drawer";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useSelector } from "react-redux";
import { selectCurrentSessionId } from "../ducks/app/session/selectors";
import { matchPath, Link, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { isMobile } from "../helpers/mobile";

export default function AppTopBar(props: {
  darkMode: boolean;
  setDarkMode: (mode: boolean) => void;
}) {
  const { t } = useTranslation("topBar");
  const { darkMode, setDarkMode } = props;
  const pathname = window.location.pathname;
  const [mobileOpen, setMobileOpen] = useState(false);
  const currentSessionId = useSelector(selectCurrentSessionId);

  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  if (pathname === "/") return null;

  const onRefreshButtonClick = () => {
    window.location.reload();
  };

  const backButton =
    matchPath(
      { path: "sessions/:sessionId/stops/:stopId/add-note" },
      pathname
    ) ||
    matchPath(
      { path: "sessions/:sessionId/stops/:stopId/add-weight" },
      pathname
    ) ||
    matchPath(
      { path: "sessions/:sessionId/stops/:stopId/add-cubic-meters" },
      pathname
    ) ||
    matchPath(
      { path: "sessions/:sessionId/waypoints/:waypointId" },
      pathname
    ) ||
    matchPath({ path: "sessions/:sessionId/stops/:stopId" }, pathname);

  const authPages =
    // matchPath({ path: "routes/login" }, pathname) ||
    matchPath({ path: "login" }, pathname);
  const exitPageButton = matchPath(
    { path: "sessions/:sessionId/cargos/add" },
    pathname
  );
  return (
    <Box sx={{ flexGrow: 1 }}>
      {!authPages && (
        <AppBar position="static">
          <Toolbar>
            {isMobile && (
              <Box sx={{
                position: "absolute",
                top: 'calc(-1*env(safe-area-inset-top))',
                left: 0,
                right: 0,
                height: 'env(safe-area-inset-top)',
                backgroundColor: '#4F76BA'
              }}></Box>
            )}
            {backButton && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                onClick={() => navigate(-1)}
              >
                <KeyboardArrowLeftRoundedIcon />
              </IconButton>
            )}
            {exitPageButton && (
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                component={Link}
                to={`/sessions/${currentSessionId}`}
              >
                <CloseOutlinedIcon />
              </IconButton>
            )}
            {!exitPageButton && !backButton ? (
              <Box sx={{ flexGrow: 1, alignSelf: "center" }}>
                <Button
                  onClick={onRefreshButtonClick}
                  variant="outlined"
                  startIcon={<SyncIcon />}
                  sx={{
                    color: "white",
                    borderColor: "white",
                  }}
                >
                  {t("update")}
                </Button>
              </Box>
            ) : (
              <Box sx={{ flexGrow: 1 }}></Box>
            )}
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
            <DrawerAppBar
              handleDrawerToggle={handleDrawerToggle}
              mobileOpen={mobileOpen}
              darkMode={darkMode}
              setDarkMode={setDarkMode}
            />
          </Toolbar>
        </AppBar>
      )}
    </Box>
  );
}
