import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";

let savedUrl: string | undefined;

const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      savedUrl = event.url;
      const url = new URL(event.url);
      let slug = url.pathname + url.search;
      console.log("Got appUrlOpen slug", slug);

      if (slug.startsWith("/m")) {
        slug = slug.replace("/m", "");
      }
      console.log("Navigating to", slug);

      if (slug) {
        navigate(slug);
      }
      // If no match, do nothing - let regular routing logic take over
      return () => {
        App.removeAllListeners();
      };
    });
  }, []);

  return null;
};

export default AppUrlListener;
