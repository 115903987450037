import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton";
import { SyntheticEvent, useState } from "react";
import { selectStopComments } from "../../../ducks/app/session/selectors";
import { useSelector } from "react-redux";
import { Stop } from "../../../ducks/app/session/types";
import * as actions from "../../../ducks/app/session";
import { List, ListItem, ListItemText } from "@mui/material";
import { DateTime } from "luxon";
import { useAppDispatch } from "../../../redux-store";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import { selectCurrentRouteId } from "../../../ducks/data/route-with-stops/selectors";
import useFeature from "../../../hooks/useFeature";
import { loadRouteWithStops } from "../../../ducks/data/route-with-stops";
import { isMobile } from "../../../helpers/mobile";

const AddCommentModal = (props: {
  sessionId: string;
  stop: Stop;
  onClose: () => void;
}) => {
  const { sessionId, stop, onClose } = props;
  const { t } = useTranslation("stopPage");
  const [comment, setComment] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const comments = useSelector(selectStopComments(stop.id));

  const currentRouteId = useSelector(selectCurrentRouteId);
  const newMobileStopsPage = isMobile || useFeature("new-mobile-stops-page");

  const onAddClick = async (e: SyntheticEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await dispatch(
        actions.addComment({
          sessionId,
          comment,
          cargoId: stop.cargo_id,
          stopId: stop.id,
        })
      ).unwrap();
      if (newMobileStopsPage) {
        dispatch(loadRouteWithStops(currentRouteId!));
      }
      onClose();
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <div className="px-8 py-4">
        <Typography variant="h6" component="h2" pt={2}>
          {t("notes.heading")}
        </Typography>
        <List>
          {comments.map((c, i) => (
            <ListItem
              sx={{ px: 2 }}
              key={c.createdAt}
              disableGutters
              divider={i < comments.length - 1}
            >
              <ListItemText
                primary={c.comment}
                secondary={DateTime.fromISO(c.createdAt).toLocaleString(
                  DateTime.DATETIME_SHORT
                )}
              />
            </ListItem>
          ))}
        </List>
        <form onSubmit={onAddClick}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoFocus
                fullWidth
                type="text"
                value={comment}
                label={t("notes.textFieldLabel")}
                onChange={(e) => {
                  setComment(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <div className="flex gap-2 justify-between w-100 mt-4">
                <Button
                  variant="outlined"
                  size="large"
                  fullWidth
                  onClick={onClose}
                >
                  {t("notes.backButtonLabel")}
                </Button>
                <LoadingButton
                  loading={loading}
                  type="submit"
                  fullWidth
                  size="large"
                  variant="contained"
                >
                  <span>{t("notes.addButtonLabel")}</span>
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </div>
    </Dialog>
  );
};

export default AddCommentModal;
