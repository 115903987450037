import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FeatureName } from "dora-contracts";
import mapValues from "lodash/mapValues";
import { apiFetch } from "../../helpers/apiRequest";

const FEATURES_LOCAL_STORAGE_KEY = "features";
const prefix = "features";

type Feature = { enabled: boolean };
type State = Partial<Record<FeatureName, Feature>>;

let initialState: State = {};

const initialValuesFromLocalStorage = localStorage.getItem(
  FEATURES_LOCAL_STORAGE_KEY
);
if (initialValuesFromLocalStorage) {
  initialState = JSON.parse(initialValuesFromLocalStorage);
}

export const initialize = createAsyncThunk(
  "/initialize",
  async (_, { dispatch }) => {
    // TODO: driver api?
    const res = await apiFetch("/drivers-api/features");
    const data = await res.json();
    if (typeof data !== "object") return;

    const values = mapValues(data, (v) => ({
      enabled: !!v.enabled,
    }));
    localStorage.setItem(FEATURES_LOCAL_STORAGE_KEY, JSON.stringify(values));
    return dispatch(slice.actions.setFeatures(values));
  }
);

const slice = createSlice({
  initialState,
  name: prefix,
  reducers: {
    setFeatures: (_, action: PayloadAction<State>) => {
      return action.payload;
    },
  },
});

export default slice.reducer;
