import type { CapacitorConfig } from "@capacitor/cli";

const config: CapacitorConfig = {
  appId: "dk.doranordic.app",
  appName: "DORA",
  webDir: "dist",
  server: {
    allowNavigation: ["*"],
    cleartext: true,
    androidScheme: "http",
  },
  ios: {
    contentInset: "never",
  },
  plugins: {
    CapacitorCookies: {
      enabled: true,
    },
    CapacitorHttp: {
      enabled: true,
    },
    CapacitorUpdater: {
      publicKey:
        "-----BEGIN RSA PUBLIC KEY-----\nMIIBCgKCAQEAtbbFV270gQ8s7tTIlX+EdPuQTqrFxhtq18Po6esxwjl3V0oD+2wE\ntMSkW265gV2GlfM/68GXoswbHtYCHDuUptlVv1wbt8e+1ppeZH72fHmuQM+0Xq7h\njuIqYo43dz9G/sGcd0O5KmLUIv2BdrmNQXyOSHxAFj7iFsY2Tz93FGdpcKuFVYlL\n42kNcYQ1+KDI+SpmfEMsrrb6ku2CG7MUh5kWj+GRFI/xdhYCvVTfjZaBgxnrmnS1\n5QmJ/Jf8/zm4usXDT3aaQnm9a8euHREr1oefOfa3YeacWl5uV7Sym5B+FjzjII5z\ngoGmasT7lC9Q8StBVTMR2B2tMMeKCPbAmQIDAQAB\n-----END RSA PUBLIC KEY-----\n",
      autoUpdate: true,
      appId: "dk.doranordic.app",
      // Do not change the "placeholder" value, it is replaced by the version in the build process
      version: "placeholder",
    },
  },
};

export default config;
