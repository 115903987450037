import { useState, useMemo } from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PlaceSearch from "./PlaceSearch/PlaceSearch";
import * as redux from "../../redux-store";
import { addCargo } from "../../ducks/app/add-cargo";
import { cargoTypes } from "./types";
import CargoTypesSelector from "./CargoTypesSelector";
import Decimal from "decimal.js-light";
import { LoadingButton } from "@mui/lab";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useClients } from "../../ducks/data/clients/hooks";
import StopDateTimeFields from "../../components/StopDateTimeFields";
import { selectHideLoadmeters } from "../../ducks/data/driver-app-settings/selectors";
import { useAppSelector } from "../../redux-store";
import { SearchOption } from "./PlaceSearch/SearchOption";
import { selectDefaultCargoType } from "../../ducks/auth/selectors";
import ClientSelector from "./ClientSelector";
import Dialog from "@mui/material/Dialog";
import { Button } from "@mui/material";
import useFeature from "../../hooks/useFeature";
import { isMobile } from "../../helpers/mobile";

const AddNewCargoDialog = ({
  sessionId,
  onClose,
}: {
  sessionId: string;
  onClose: (result?: { cargoId: string; pickupId: string }) => void;
}) => {
  const dispatch = redux.useAppDispatch();
  const { t } = useTranslation(["translation", "addDropoffPage"]);
  const defaultCargoType = useAppSelector(selectDefaultCargoType);

  let [form, setForm] = useState({
    cargoType: defaultCargoType || cargoTypes[0],
    clientId: null as string | null,
    // description: "",
  });
  const clients = useClients();
  const now = useMemo(() => DateTime.now(), []);
  const [description, setDescription] = useState("");
  const [invoiceRef, setInvoiceRef] = useState("");
  const [lm, setLm] = useState("13.6");
  const [pickupTime, setPickupTime] = useState(now);
  const [dropoffDate /*, setDropoffDate*/] = useState(now.toJSDate());
  const [pickupLocation, setPickupLocation] = useState<SearchOption | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const hideLoadmeters = useAppSelector(selectHideLoadmeters);
  const newStopsPageEnabled = isMobile || useFeature("new-mobile-stops-page");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!pickupLocation) {
      return;
    }
    try {
      setLoading(true);
      if (!pickupLocation) {
        return;
      }
      // TODO!
      const result = await dispatch(
        addCargo({
          sessionId,
          invoiceRef,
          lm: new Decimal(lm),
          description,
          pickupTime: pickupTime,
          dropoffDate: DateTime.fromJSDate(dropoffDate),
          getPickupLocation: {
            getCargoLocation: async () => {
              switch (pickupLocation.type) {
                case "REVERSE_GEOCODE_OPTION":
                case "SEARCH_RESULT":
                  const lookupData = await pickupLocation.value.data.lookup();
                  return {
                    googlePlaceId: pickupLocation.value.data.id,
                    placeName: lookupData.placeName || "",
                    country: lookupData.countryCode,
                    position: lookupData.coords,
                    address: lookupData.address,
                    city: lookupData.city,
                    postcode: lookupData.postcode,
                  };
                case "SAVED_LOCATION": {
                  return {
                    placeName: pickupLocation.value.data.place.placeName || "",
                    country: pickupLocation.value.data.place.country,
                    position: pickupLocation.value.data.place.coord,
                    address: pickupLocation.value.data.place.address,
                    city: pickupLocation.value.data.place.city,
                    postcode: pickupLocation.value.data.place.postcode,
                  };
                }
              }
            },
          },
          skipCompleteEvent: !!newStopsPageEnabled,
          ...form,
        })
      );
      if (result) {
        onClose(result);
        // navigate(`/sessions/${sessionId}/stops/${result.pickupId}`);
      } else {
        // navigate(`/sessions/${sessionId}`);
      }
    } finally {
      setLoading(false);
    }
  };

  if (!clients) {
    return null;
  }

  return (
    <Dialog open fullScreen onClose={() => onClose()}>
      <div className="px-4 py-2 mt-4">
        <Typography variant="h5" component="h1">
          {t("AddNewCargo")}
        </Typography>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <CargoTypesSelector
            value={form.cargoType}
            onValueChange={(cargoType) => setForm((f) => ({ ...f, cargoType }))}
          />
          <ClientSelector
            selectedClientId={form.clientId}
            onClientChange={(id) =>
              setForm((data) => ({
                ...data,
                clientId: id,
              }))
            }
          />
          <TextField
            label={t("invoiceRefField")}
            margin="dense"
            fullWidth
            value={invoiceRef}
            onChange={(e) => {
              setInvoiceRef(e.target.value);
            }}
          />
          <TextField
            label={t("descriptionField")}
            margin="dense"
            fullWidth
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
          />
          {!hideLoadmeters && (
            <TextField
              label={t("loadmeterField")}
              margin="dense"
              fullWidth
              type="number"
              required
              InputProps={{
                componentsProps: {
                  input: {
                    inputMode: "decimal",
                  },
                },
              }}
              value={lm}
              onChange={(e) => setLm(e.target.value)}
            />
          )}
          <StopDateTimeFields
            dateFieldLabel={t("pickupDateFieldLabel")}
            timeFieldLabel={t("pickupTimeFieldLabel")}
            value={pickupTime}
            onChange={setPickupTime}
          />
          <PlaceSearch
            label={t("pickupAddressField")}
            location={pickupLocation}
            onLocationChange={setPickupLocation}
          />
          <LoadingButton
            loading={loading}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
            sx={{ p: 2, mt: 3, mb: 2 }}
          >
            {t("submitButtonText")}
          </LoadingButton>
          <Button
            variant="outlined"
            size="large"
            fullWidth
            onClick={() => onClose()}
          >
            {t("addDropoffPage:cancelButtonLabel")}
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

export default AddNewCargoDialog;
