import Typography from "@mui/material/Typography";
import { Link, useNavigate } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
// import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
// import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import Stack from "@mui/material/Stack";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import ArrowCircleUpRoundedIcon from "@mui/icons-material/ArrowCircleUpRounded";
import ArrowCircleDownRoundedIcon from "@mui/icons-material/ArrowCircleDownRounded";
import { useSelector } from "react-redux";
import {
  selectCargo,
  selectCurrentSessionId,
  selectIsStopCompleted,
  selectStopClient,
} from "../../ducks/app/session/selectors";
import { Stop } from "../../ducks/app/session/types";
import CheckIcon from "@mui/icons-material/Check";
import { useTranslation } from "react-i18next";
import RouteAddress from "./RouteAddress";
import { Button } from "@mui/material";
import { useL10n } from "../../l10n";
import { useState } from "react";
import AddDropoffDialog from "./AddDropoffDialog";
import useFeature from "../../hooks/useFeature";
import { isMobile } from "../../helpers/mobile";

const StopAvatar = ({ stop }: { stop: Stop }) => {
  const isCompleted = useSelector(selectIsStopCompleted(stop.id));

  if (isCompleted) {
    return <CheckIcon />;
  }
  switch (stop.type) {
    case "PICKUP":
      return <ArrowCircleUpRoundedIcon />;
    case "DROPOFF":
      return <ArrowCircleDownRoundedIcon />;
    default:
      return null;
  }
};

const RouteStop = ({ stop }: { stop: Stop }) => {
  const { t } = useTranslation();
  const [shouldShowAddDropoffDialog, setShouldShowAddDropoffDialog] =
    useState(false);
  const isNewPageEnabled = isMobile || useFeature("new-mobile-stops-page");
  const sessionId = useSelector(selectCurrentSessionId);
  const cargo = useSelector(selectCargo(stop.cargo_id));
  const client = useSelector(selectStopClient(stop.id));
  const stopUrl = `stops/${stop.id}`;
  const dropoffUrl = `cargos/${stop.cargo_id}/add-dropoff`;
  const navigate = useNavigate();
  const onClick = () => {
    navigate(stopUrl);
  };
  const l10n = useL10n();

  return (
    <div style={{ paddingLeft: stop.type === "DROPOFF" ? 36 : 0 }}>
      {shouldShowAddDropoffDialog && (
        <AddDropoffDialog
          sessionId={sessionId!}
          cargoId={stop.cargo_id}
          onClose={() => setShouldShowAddDropoffDialog(false)}
          onSuccess={(newStopId) => {
            setShouldShowAddDropoffDialog(false);
            if (!isNewPageEnabled) {
              navigate(`stops/${newStopId}`);
            }
          }}
        />
      )}
      <ListItem
        key={stop.id}
        disablePadding
        secondaryAction={
          <Stack
            direction="column"
            alignItems="flex-end"
            justifyContent="flex-start"
          >
            {cargo?.driverCanAddDropoffs && stop.type === "PICKUP" ? (
              <IconButton
                edge="end"
                onClick={() => setShouldShowAddDropoffDialog(true)}
              >
                <Button variant="outlined" size="small">
                  {t("add")}
                  <br /> {t("delivery")}
                </Button>
              </IconButton>
            ) : null}
            {/*  */}
            {/* <Typography variant="overline">
                        {t("LoadmeterAbbreviation", {
                            count: +stop.lm,
                        })}
                    </Typography> */}
            {/* {cargo?.driverCanAddDropoffs && stop.type === "PICKUP" ? (
                        <IconButton edge="end" component={Link} to={dropoffUrl}>
                            <Button
                                variant="outlined"
                                startIcon={
                                    <AddCircleOutlineIcon fontSize="large" />
                                }
                                size="small"
                            >
                                levering
                            </Button>
                        </IconButton>
                    ) : null} */}
          </Stack>
        }
      >
        <ListItemButton
          disableRipple
          disableTouchRipple
          onClick={onClick}
          sx={{ px: 0 }}
        >
          <ListItemAvatar>
            <StopAvatar stop={stop} />
          </ListItemAvatar>
          <ListItemText
            primary={
              stop.place && (
                <Typography variant="body1">
                  <RouteAddress address={stop.place} />
                </Typography>
              )
            }
            secondary={
              <>
                {stop.date && <span>{l10n.formatDate(stop.date)}</span>}
                {stop.fixTime && <br />}
                {stop.fixTime && (
                  <span>
                    {t("Fix") + ": " + (stop.openingTime || stop.time)}
                  </span>
                )}
                {client && <br />}
                {client && (
                  <Typography variant="overline">{client?.name}</Typography>
                )}
                {cargo?.driverCanAddDropoffs && <br />}
                {cargo?.driverCanAddDropoffs && stop.type === "PICKUP" ? (
                  <Typography variant="overline">
                    {t("droppedOff")}: {cargo.noOfDropoffs}
                  </Typography>
                ) : null}
                {/* {cargo?.driverCanAddDropoffs &&
                            stop.type === "PICKUP" ? (
                                <>
                                    No of dropoffs: {cargo.noOfDropoffs}
                                    <br />
                                    <Button
                                        variant="outlined"
                                        size="small"
                                        // component={Link}
                                        onClick={onDropoffClick}
                                    >
                                        {t("addDropoffButtonText")}
                                    </Button>
                                </>
                            ) : null} */}
              </>
            }
          />
        </ListItemButton>
      </ListItem>
    </div>
  );
};

export default RouteStop;
